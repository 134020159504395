import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import ReactHtmlParser from "react-html-parser";
import { GatsbyImage } from "gatsby-plugin-image";
import { ArtistCard } from "../utils/exhibition";

class Exhibition extends React.Component {
  render() {
    const { data } = this.props;
    const exhibition = data.allPrismicExhibitions.nodes[0];

    return (
      <Layout>
        <section className="container">
          <div className="level">
            <div className="level-left">
              <p className="title is-3">{exhibition.data.name.text}</p>
            </div>
          </div>
          <div className="card">
            <div className="card-image">
              <figure className="image">
                <GatsbyImage
                  alt={exhibition.data.name.text}
                  image={exhibition?.data?.banner_image?.gatsbyImageData}
                />
              </figure>
            </div>
            <div className="card-content">
              <div className="content">
                {ReactHtmlParser(exhibition.data.exhibition_writeup.html)}
              </div>
            </div>
          </div>
        </section>
        <section className="container mt-5">
          <div className="level">
            <div className="level-left">
              <div className="media">
                <div className="media-content">
                  <p className="title is-4">Participating Artists</p>
                  <p className="subtitle is-5">
                    Click on an artist to see their work
                  </p>
                </div>
              </div>
            </div>
          </div>
          {exhibition?.data?.participating_artists &&
            exhibition?.data?.participating_artists.length > 0 &&
            Array.from(
              {
                length: Math.ceil(
                  exhibition?.data?.participating_artists.length / 3
                ),
              },
              (_, i) => i
            ).map((i) => {
              return (
                <div className="tile is-ancestor" key={`artist-tile-${i}`}>
                  {exhibition.data.participating_artists
                    .slice(i * 3, i * 3 + 3)
                    .map((artist) => (
                      <ArtistCard
                        artist={artist}
                        key={artist.handle}
                        slug={`/exhibitions/${exhibition.uid}`}
                      />
                    ))}
                </div>
              );
            })}
        </section>
      </Layout>
    );
  }
}

export const exhibitionQuery = graphql`
  query exhibitionQuery($handle: String!) {
    allPrismicExhibitions(filter: { uid: { eq: $handle } }) {
      nodes {
        uid
        data {
          start_date
          end_date
          banner_image {
            alt
            url
            gatsbyImageData
          }
          name {
            text
          }
          exhibition_writeup {
            html
          }
          participating_artists {
            handle
            artist_name {
              text
            }
            artist_profile_description {
              html
            }
            artist_profile_picture {
              gatsbyImageData
            }
            artist_sub_header {
              text
            }
          }
        }
      }
    }
  }
`;

export default Exhibition;
