import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { Link } from "gatsby";
import { DateTime } from "luxon";
import ReactHtmlParser from "react-html-parser";

export const ExhibitionPreview = ({ exhibition }) => {
  return (
    <div className="tile is-parent is-12">
      <div className="tile is-child card">
        <div className="card-image">
          <figure className="image">
            <GatsbyImage
              alt={exhibition.data.name.text}
              image={exhibition?.data?.banner_image?.gatsbyImageData}
            />
          </figure>
        </div>
        <div className="card-content">
          <div className="level">
            <div className="level-left">
              <div className="media">
                <div className="media-content">
                  <p className="title is-4">{exhibition.data.name.text}</p>
                  <p className="subtitle">
                    From{" "}
                    {DateTime.fromFormat(
                      exhibition.data.start_date,
                      "yyyy-MM-dd"
                    ).toLocaleString(DateTime.DATE_MED)}{" "}
                    to{" "}
                    {DateTime.fromFormat(
                      exhibition.data.end_date,
                      "yyyy-MM-dd"
                    ).toLocaleString(DateTime.DATE_MED)}
                  </p>
                </div>
              </div>
            </div>
            <div className="level-right">
              <Link to={`/exhibitions/${exhibition.uid}`}>
                <button className="button">View Exhibition</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ArtistCard = ({ artist, slug }) => {
  return (
    <div className="tile is-parent is-4">
      <Link to={`${slug}/${artist.handle}`}>
        <div className="tile is-child card">
          <div className="card-image">
            <figure className="image">
              <GatsbyImage
                alt={artist.artist_name.text}
                image={artist?.artist_profile_picture?.gatsbyImageData}
              />
            </figure>
          </div>
          <div className="card-content">
            <div className="level">
              <div className="level-left">
                <div className="media">
                  <div className="media-content">
                    <p className="title">{artist.artist_name.text}</p>
                    <p className="subtitle is-6">
                      {artist.artist_sub_header.text}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="content">
              {ReactHtmlParser(artist.artist_profile_description.html)}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
